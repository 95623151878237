import { User } from '@supabase/supabase-js'
import { formatToBRL } from 'brazilian-values'
import { supabase } from '../services/supabase'
import { cities, states } from './address'
import { InstructorAdvertisement } from '@/stores/advertisements/types'
import { Appointment } from '@/stores/appointments/types'

export const onlyNumbers = (text: string) => text.replace(/\D/g, '')

export const capitalize = (text: string) => text.charAt(0).toUpperCase() + text.slice(1)

export const serializeState = (value: string) =>
  states.find(state => state.value === value)?.label || ''

export const serializeCity = (value: string) =>
  cities.find(city => city.value === value)?.label || ''

export const serializeUser = (user: User) => {
  const { data } = supabase.storage
    .from('profiles')
    .getPublicUrl(user?.user_metadata?.avatar || '')

  if (data) {
    const { publicUrl } = data

    return {
      ...user,
      user_metadata: {
        ...user?.user_metadata,
        avatar: publicUrl,
      },
    }
  }

  return user
}

export const serializeInstructorAdvertisement = (
  instructorAdvertisement: InstructorAdvertisement
) => {
  const { data } = supabase.storage
    .from('profiles')
    .getPublicUrl(instructorAdvertisement?.instructor?.avatar || '')

  if (data) {
    const { publicUrl } = data

    return {
      ...instructorAdvertisement,
      instructor: {
        ...instructorAdvertisement?.instructor,
        avatar: publicUrl,
      },
    }
  }

  return instructorAdvertisement
}

export const serializeInstructors = (instructors: InstructorAdvertisement[]) => {
  return instructors.map(instructor => {
    const { data } = supabase.storage
      .from('profiles')
      .getPublicUrl(instructor?.instructor?.avatar || '')

    if (data) {
      const { publicUrl } = data

      return {
        ...instructor,
        instructor: {
          ...instructor?.instructor,
          avatar: publicUrl,
        },
      }
    }

    return instructor
  })
}

export const serializeStats = (appointments: Appointment[]) => {
  const doneAppointments = appointments.filter(
    appointment => appointment.status === 'done'
  )

  return {
    conversion: `${(doneAppointments.length / appointments.length) * 100 || 0}%`,
    appointments: appointments.length || 0,
    revenue: formatToBRL(
      doneAppointments.reduce(
        (accumulator, currentValue) => accumulator + Number(currentValue.totalValue),
        0
      ) || 0
    ),
  }
}

export const serializeAppointments = (appointments: Appointment[]) =>
  appointments.sort((a, b) => {
    const orders = {
      pending: 1,
      confirmed: 2,
      canceled: 3,
      done: 4,
    }

    return orders[a.status] - orders[b.status]
  })

export const serializeInitials = (fullName: string) => {
  return fullName
    ?.match(/(\b\S)?/g)
    ?.join('')
    ?.match(/(^\S|\S$)?/g)
    ?.join('')
}

export const serializeWhatsapp = (phoneNumber: string) => {
  return `https://api.whatsapp.com/send?phone=55${phoneNumber}`
}
