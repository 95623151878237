import { yupResolver } from '@hookform/resolvers/yup'
import { useState } from 'react'
import { Helmet } from 'react-helmet'
import { useForm } from 'react-hook-form'
import * as Yup from 'yup'

import background from '@/assets/background-2x.jpg'
import logo from '@/assets/logo.svg'
import { Button } from '@/components/Button'
import { Input } from '@/components/Input'
import { supabase } from '@/services/supabase'
import { notifyFailure, notifySuccess } from '@/utils/toast'

const validationSchema = Yup.object().shape({
  email: Yup.string().email('Digite um e-mail válido.').required('Campo obrigatório.'),
})

type FormData = Yup.InferType<typeof validationSchema>

export function Launch() {
  const [submitted, setSubmitted] = useState(false)
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<FormData>({
    resolver: yupResolver(validationSchema),
  })

  const onSubmit = async (values: FormData) => {
    try {
      const { email } = values
      const { error, data } = await supabase.from('leads').insert({ email }).select()

      if (error) throw error

      if (data) {
        notifySuccess({
          title: 'Oba!',
          description: 'Seu e-mail foi enviado com sucesso',
        })
        setSubmitted(true)
      }
    } catch (error) {
      notifyFailure({ title: 'Ops!', description: 'Algo deu errado, tente novamente' })
    }
  }

  return (
    <>
      <Helmet>
        <title>Gowto | Beta</title>
      </Helmet>

      <div className="flex min-h-full flex-1">
        <div className="flex flex-1 flex-col justify-center px-4 py-12 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
          <div className="mx-auto w-full max-w-sm lg:w-96">
            <div>
              <img className="h-10 w-auto" src={logo} alt="gowto" />
              <h2 className="mt-8 text-2xl font-bold leading-9 tracking-tight text-gray-900">
                {submitted
                  ? 'Obrigado'
                  : 'Feito para instrutores particulares de motoristas habilitados.'}
              </h2>
              <p className="mt-2 text-sm leading-6 text-gray-500">
                {submitted
                  ? 'Seu e-mail foi enviado com sucesso. Em breve, entraremos em contato com mais novidades.'
                  : 'Simplifique o gerenciamento de suas aulas, mantenha controle sobre suas métricas e aumente sua visibilidade para mais motoristas habilitados.'}
              </p>
            </div>

            {!submitted && (
              <div className="mt-10">
                <div>
                  <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
                    <Input
                      type="email"
                      label="E-mail"
                      placeholder="Digite seu melhor e-mail"
                      error={errors.email?.message}
                      {...register('email')}
                    />

                    <div>
                      <Button type="submit" loading={isSubmitting}>
                        Quero testar grátis
                      </Button>
                    </div>
                  </form>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="relative hidden w-0 flex-1 lg:block">
          <img
            className="absolute inset-0 h-full w-full object-cover"
            src={background}
            alt="Mulher dirigindo"
          />
        </div>
      </div>
    </>
  )
}
