import { EyeIcon, EyeSlashIcon } from '@heroicons/react/20/solid'
import classNames from 'classnames'
import React, { DetailedHTMLProps, InputHTMLAttributes, useState } from 'react'

type InputProps = {
  label?: string
  error?: string
  helperText?: string
} & DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>

export const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ type = 'text', name, label = '', error, helperText, ...rest }, ref) => {
    const [passwordType, setPasswordType] = useState(type)

    const togglePasswordType = () => {
      if (passwordType === 'password') {
        setPasswordType('text')
        return
      }

      setPasswordType('password')
    }

    const renderHelperText = () => {
      if (error) {
        return (
          <p className="mt-2 text-sm text-red-600" id="email-error">
            {error}
          </p>
        )
      }

      if (helperText) {
        return <p className="mt-2 text-sm text-gray-500">{helperText}</p>
      }

      return null
    }

    return (
      <div>
        {label && (
          <label
            htmlFor={name}
            className="inline-block mb-2 text-sm font-medium leading-6 text-gray-900"
          >
            {label}
          </label>
        )}

        <div className="relative">
          <input
            type={type === 'password' ? passwordType : type}
            name={name}
            id={name}
            className={classNames(
              'block w-full rounded-md border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-500 disabled:ring-gray-200',
              {
                'text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500':
                  !!error,
              }
            )}
            ref={ref}
            {...rest}
          />

          {type === 'password' && (
            <button
              type="button"
              className="absolute inset-y-0 right-0 flex items-center pr-3 cursor-pointer"
              onClick={togglePasswordType}
            >
              {passwordType === 'password' ? (
                <EyeIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
              ) : (
                <EyeSlashIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
              )}
            </button>
          )}
        </div>
        {renderHelperText()}
      </div>
    )
  }
)
