import { XMarkIcon } from '@heroicons/react/20/solid'
import { CheckCircleIcon } from '@heroicons/react/24/outline'

type AlertProps = {
  variant: 'success' | 'failure'
  title: string
  description: string
  onClose: () => void
}

export function Alert({ variant, title, description, onClose }: AlertProps) {
  const renderIcon = () => {
    const icons = {
      success: <CheckCircleIcon className="h-6 w-6 text-green-400" aria-hidden="true" />,
      failure: <CheckCircleIcon className="h-6 w-6 text-red-400" aria-hidden="true" />,
    }

    return icons[variant] || null
  }

  return (
    <>
      <div className="mr-3">{renderIcon()}</div>

      <div>
        <p className="text-sm font-medium text-gray-900">{title}</p>
        <p className="mt-1 text-sm text-gray-500">{description}</p>
      </div>

      <div className="ml-4">
        <button
          type="button"
          className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          onClick={onClose}
        >
          <span className="sr-only">Close</span>
          <XMarkIcon className="h-5 w-5" aria-hidden="true" />
        </button>
      </div>
    </>
  )
}
