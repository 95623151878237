import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'

import background from '@/assets/background-2x.jpg'
import logo from '@/assets/logo.svg'

export function NotFound() {
  return (
    <>
      <Helmet>
        <title>Gowto | 404</title>
      </Helmet>
      <div className="relative flex min-h-full shrink-0 justify-center md:px-12 lg:px-0">
        <div className="relative z-10 flex flex-1 flex-col bg-white px-4 py-10 shadow-2xl sm:justify-center md:flex-none md:px-28">
          <main className="mx-auto w-full max-w-md sm:px-4 md:w-96 md:max-w-sm md:px-0">
            <div className="flex">
              <Link to="/" aria-label="Início">
                <img src={logo} alt="Gowto" className="h-10 w-auto" />
              </Link>
            </div>
            <p className="mt-20 text-sm font-medium leading-6 text-gray-700">404</p>
            <h1 className="mt-3 text-lg font-semibold leading-8 text-gray-900">
              Página não encontrada
            </h1>
            <p className="mt-3 mb-10 text-sm leading-6 text-gray-700">
              Desculpa, não conseguimos encontrar a página que você estava procurando.
            </p>
            <Link
              to="/"
              className="group inline-flex items-center justify-center rounded-full py-2 px-4 text-sm leading-6 font-semibold focus:outline-none focus-visible:outline-2 focus-visible:outline-offset-2 bg-indigo-600 text-white hover:text-slate-100 hover:bg-indigo-500 active:bg-indigo-800 active:text-indigo-100 focus-visible:outline-indigo-600"
            >
              Voltar
            </Link>
          </main>
        </div>
        <div className="hidden sm:contents lg:relative lg:block lg:flex-1">
          <img
            className="absolute inset-0 h-full w-full object-cover"
            src={background}
            alt="Mulher dirigindo"
          />
        </div>
      </div>
    </>
  )
}
