import 'core-js/stable'

import { Bubble } from '@typebot.io/react'
import { useEffect } from 'react'
import { isMobile } from 'react-device-detect'
import { Toaster } from 'react-hot-toast'
import { useShallow } from 'zustand/react/shallow'

import { Routes } from './routes'
import { supabase } from './services/supabase'
import { useAuth } from './stores/auth'

export default function App() {
  const { setUser, setLoading } = useAuth(
    useShallow(state => ({
      setUser: state.setUser,
      setLoading: state.setLoading,
    }))
  )

  useEffect(() => {
    setLoading(true)

    supabase.auth.getSession().then(({ data: { session } }) => {
      setUser(session?.user ?? null)
      setLoading(false)
    })

    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_, session) => {
      setUser(session?.user ?? null)
      setLoading(false)
    })

    return () => subscription.unsubscribe()
  }, [setLoading, setUser])

  return (
    <>
      <Routes />
      <Toaster position={isMobile ? 'top-center' : 'top-right'} reverseOrder={false} />
      <Bubble
        typebot="customer-support-tnmuidy"
        theme={{ button: { backgroundColor: '#4f46e5' } }}
      />
    </>
  )
}
