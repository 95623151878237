import { toast } from 'react-hot-toast'

import { Alert } from '../components/Alert'

type Notification = {
  title: string
  description: string
}

export const notifySuccess = ({ title, description }: Notification) =>
  toast(({ id }) => (
    <Alert
      variant="success"
      title={title}
      description={description}
      onClose={() => toast.dismiss(id)}
    />
  ))

export const notifyFailure = ({ title, description }: Notification) =>
  toast(({ id }) => (
    <Alert
      variant="failure"
      title={title}
      description={description}
      onClose={() => toast.dismiss(id)}
    />
  ))
