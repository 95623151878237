import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'

// import { PrivateRoute } from './PrivateRoute'
// import { PublicRoute } from './PublicRoute'
// import { Advertisement } from '@/pages/Advertisement'
// import { Appointment } from '@/pages/Appointment'
// import { Appointments } from '@/pages/Appointments'
// import { Home } from '@/pages/Home'
// import { Instructors } from '@/pages/Instructors'
import { Launch } from '@/pages/Launch'
import { NotFound } from '@/pages/NotFound'
// import { Profile } from '@/pages/Profile'
// import { SignIn } from '@/pages/SignIn'
// import { SignUp } from '@/pages/SignUp'
// import { useAuth } from '@/stores/auth'

export function Routes() {
  // const { loading } = useAuth()

  // if (loading) {
  //   return (
  //     <div className="flex items-center justify-center fixed h-screen w-screen bg-gray-50">
  //       <div className="animate-spin inline-block w-10 h-10 border-[3px] border-current border-t-indigo-100 text-indigo-600 rounded-full" />
  //     </div>
  //   )
  // }

  return (
    <Router>
      <Switch>
        <Route path="/" exact>
          <Launch />
        </Route>

        {/* <Route path="/" exact>
          <Home />
        </Route>

        <Route path="/instrutores" exact>
          <Instructors />
        </Route>

        <Route path="/agendamento/:instructorId" exact>
          <Appointment />
        </Route>

        <PublicRoute path="/entrar" exact>
          <SignIn />
        </PublicRoute>

        <PublicRoute path="/cadastrar" exact>
          <SignUp />
        </PublicRoute>

        <PrivateRoute path="/perfil" exact>
          <Profile />
        </PrivateRoute>

        <PrivateRoute path="/anuncio" exact>
          <Advertisement />
        </PrivateRoute>

        <PrivateRoute path="/agendamentos" exact>
          <Appointments />
        </PrivateRoute> */}

        <Route path="*">
          <NotFound />
        </Route>
      </Switch>
    </Router>
  )
}
