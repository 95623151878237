import { User } from '@supabase/supabase-js'
import { immer } from 'zustand/middleware/immer'

import { State, Actions } from './types'
import { supabase } from '@/services/supabase'
import { create } from '@/stores/create'
import { serializeUser } from '@/utils/serializes'
import { notifyFailure, notifySuccess } from '@/utils/toast'

export const useAuth = create<State & Actions>()(
  immer((set, get) => ({
    user: null,
    isAuthenticated: false,
    loading: false,

    setUser: async (user: User | null) => {
      set(state => {
        state.user = serializeUser(user as User)
        state.isAuthenticated = !!user
      })
    },

    setLoading: loading =>
      set(state => {
        state.loading = loading
      }),

    signIn: async credentials => {
      try {
        const { error } = await supabase.auth.signInWithPassword(credentials)
        if (error) throw error
      } catch {
        notifyFailure({
          title: 'Ops!',
          description: 'Algo deu errado, verifique seus dados',
        })
      }
    },

    signUp: async credentials => {
      try {
        const { error } = await supabase.auth.signUp(credentials)
        if (error) throw error
      } catch (error) {
        notifyFailure({
          title: 'Ops!',
          description: 'Algo deu errado, verifique seus dados',
        })
      }
    },

    signOut: async () => {
      await supabase.auth.signOut()
    },

    updateUser: async user => {
      try {
        const { error, data } = await supabase.auth.updateUser(user)
        if (error) throw error

        if (data) {
          notifySuccess({ title: 'Oba!', description: 'Seu perfil foi atualizado' })
        }
      } catch {
        notifyFailure({
          title: 'Ops!',
          description: 'Algo deu errado, verifique seus dados',
        })
      }
    },

    updateAvatar: async file => {
      try {
        const userId = get().user?.id
        const { name } = file
        const extension = name.split('.').pop() as string
        const path = `${userId}/avatar.${extension}`

        const storageResponse = await supabase.storage
          .from('profiles')
          .upload(path, file, { upsert: true })

        if (storageResponse.error) throw storageResponse.error

        if (storageResponse.data) {
          const { error, data } = await supabase.auth.updateUser({
            data: { avatar: storageResponse.data.path },
          })

          if (error) throw error

          if (data) {
            notifySuccess({
              title: 'Oba!',
              description: 'Sua foto de perfil foi atualizada',
            })
          }
        }
      } catch (error) {
        notifyFailure({ title: 'Ops!', description: 'Algo deu errado, tente novamente' })
      }
    },
  }))
)
